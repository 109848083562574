<template>
    <div class="card-inner">
        <div class="between-center flex-wrap flex-md-nowrap g-3">
            <div class="nk-block-text">
                <h6>2FA Authentication <span class="badge badge-success">Enabled</span></h6>
                <p>Secure your account with 2FA security. When it is activated you will need to enter not only your password, but also a special code using app. You can receive this code by in mobile app. </p>
            </div>
            <div class="nk-block-actions">
                <a href="#" class="btn btn-primary">Disable</a>
            </div>
        </div>
    </div><!-- .card-inner -->
</template>

<script>
export default {

}
</script>

<style>

</style>