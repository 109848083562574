<template>
 <div class="nk-block">
        <div class="card card-bordered p-3">
          <div class="card-inner-group">
  <div v-if="tenants" >
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="Overview">
        <div class="row">
          <div class="col-md-8">
            <h5>Tenants</h5>
            <vue-good-table
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                :totalRows="tenants.tenants.length"
                :rows="tenants.tenants"
                :columns="columns"
                styleClass="vgt-table condensed"
                v-if="tenants"
            >
              <template slot="table-row" slot-scope="props" v-bind:class="{'xxx': true}">
                <div v-if="props.column.field == 'failed'" v-bind:class="{'bg-danger': props.row.failed === true,'bg-success': props.row.failed === false}" class="umbril_td_container text-center text-light"  >
                  <span v-if="props.row.failed">FAILED</span>
                  <span v-if="!props.row.failed">PASSED</span>
                </div>
                <div v-if="props.column.field == 'createdDate'">
                  {{ $moment(props.row.createdDate).format("LL") }}
                </div>
                <div class="umbril_td_container text-center" v-else-if="props.column.field == 'actions'" >
                  <router-link class="btn btn-xs btn-success mr-2 p5" :to="{ name: 'tenant',  query: { tenantId: props.row.id}}">
                    View
                  </router-link>
                    <a-popconfirm
                      title="Are you sure delete this tenant?"
                      ok-text="Yes"
                      cancel-text="No"
                      @confirm="deleteTenant(props.row)"
                    >
                    <button  class="btn btn-xs btn-danger">Delete</button>
                  </a-popconfirm>
                </div>
                <div v-else >
                  {{ props.formattedRow[props.column.field] }}
                </div>
              </template>
        </vue-good-table>
      </div>
      <div class="col-md-4">
        <div class="row">
          <div class="col-12">
            <a-button class="btn btn-dark btn-block mb-2" @click="openAddTenant()">Add Tenant</a-button>
          </div>
        </div>
        <h6>Tenant Stats</h6>
        <table class="table table-striped" v-if="adminStats">
            <tr>
              <td>Total Tenants</td>
              <td>{{ adminStats.tenantsCount }}</td>
            </tr>
            <tr>
              <td>Total Users</td>
              <td>{{ adminStats.usersCount }}</td>
            </tr>
        </table>
      </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Settings">
        <settings/>
      </a-tab-pane>
      <a-tab-pane key="3" tab="Updates" force-render>
        <div class="row">
        <div class="col-md-12">
                  xxx
        </div>
    </div>
      </a-tab-pane>
    </a-tabs>
  </div>
        </div>
    </div>
    <!-- MODALS START -->
    <a-modal v-model="showAddTenant" title="Add Tenant" closable="true">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="form-label">Tenant Name</label>
            <a-input class="invisibox" v-model="newTenant.name"/>
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button class="btn-success" @click="addTenant()">Save</a-button>
      </template>
    </a-modal>
      </div>
</template>
<script>
// Import FilePond
import settings from '@/components/admin/tenants/settings.vue'

export default {
  components: { settings },
  data: function () {
    return {
      columns: [
        { label: 'Name', hidden: false, field: 'name' },
        { label: 'Signed Up', hidden: false, field: 'createdDate' },
        { label: 'Users', hidden: false, field: 'userCount' },
        { label: 'Actions', hidden: false, field: 'actions', thClass: 'text-center', width: '200px' },
      ],
      tenants: null,
      adminStats: null,
      showAddTenant: false,
      newTenant: {}
    }
  },
  methods: {
    getView() {
      this.$http.get('/ViewAccess/tenants')
      .then(() => {
      })
      .catch(() => {
      })
    },
    deleteTenant(tenant) {
       this.$http.post('/admin/Delete_Tenant/',tenant)
      .then(() => {
        this.Get_Tenants()
      })
      .catch(() => {
        this.$message.error('There has been an error')
      })
    },
    Get_Tenants () {
      this.$http.get('/admin/Get_Tenants/')
      .then((response) => {
        this.tenants = response.data
      })
      .catch(() => {
        this.$message.error('There has been an error')
      })
    },
    onPageChange(){

    },
    onSortChange(){

    },
    onColumnFilter(){

    },
    onPerPageChange(){

    },
    get_AdminStats(){
      this.$http.get('/admin/Get_Admin_Stats/')
      .then((response) => {
        this.adminStats = response.data
      })
      .catch(() => {
        this.$message.error('There has been an error')
      })
    },
    openAddTenant(){
      this.showAddTenant = true
    },
    addTenant(){
      this.$http.post('/admin/Add_Tenant/', this.newTenant)
      .then(() => {
        this.$message.success('Tenant Created')
        this.newTenant = {}
        this.Get_Tenants()
      })
      .catch(() => {
        this.$message.error('There has been an error')
      })
    }
  },
  created () {
    this.getView()
    this.Get_Tenants()
    this.get_AdminStats()
  },
}
</script>
<style>
</style>
